var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",class:{
  selection: _vm.selected != null,
  close: !_vm.settings.shopOpen
}},[(!_vm.settings.shopOpen)?_c('div',{staticClass:"waiting-container"},[_c('div',{staticClass:"text-container"},[_vm._v(" La Boutique ouvre Bientot ! ")])]):_vm._e(),_c('div',{staticClass:"page-container"},[_c('span',{staticClass:"title-header"},[_vm._v(" jeux disponible "),_c('span',{staticClass:"title-count"},[_vm._v("("+_vm._s(_vm.totalAvailable)+")")])]),_c('div',{staticClass:"game-list"},_vm._l((_vm.orderedGame),function(game){return _c('div',{key:game.name,staticClass:"wrapper",on:{"click":function($event){return _vm.select(game)}}},[_c('div',{staticClass:"game",style:(Object.assign({}, game.style,
            {'background-image': ("url(" + (game.imgUrl) + ")")}))},[(!game.available)?_c('div',{staticClass:"unavalable"},[_vm._v(" Indisponible ")]):(game.claimed >= game.quantity)?_c('div',{staticClass:"unavalable"},[_vm._v(" Out Of Stock ")]):(_vm.userData.boule2noel < game.price)?_c('div',{staticClass:"unavalable"},[_vm._v(" Boules insuffisantes ")]):_vm._e(),(game.special)?_c('div',{staticClass:"special"},[_vm._v(" special "),_c('i',{staticClass:"fa fa-star"})]):_vm._e(),_c('div',{staticClass:"quantity"},[_vm._v(_vm._s(game.quantity - game.claimed)+" restants")]),_c('div',{staticClass:"price",class:{
            available: _vm.userData.boule2noel >= game.price && game.claimed < game.quantity
          }},[_vm._v(_vm._s(new Intl.NumberFormat().format(game.price))+" boules")])])])}),0),(_vm.selected)?_c('div',{staticClass:"shop-panel"},[_c('div',{staticClass:"shop-panel-content"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.selected.name.toLowerCase()))]),_c('div',{staticClass:"icon-container",on:{"click":function($event){_vm.selected = null}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('div',{staticClass:"info-container"},[_c('div',{staticClass:"game",style:({
            'background-image': ("url(" + (_vm.selected.imgUrl) + ")")
          })},[(_vm.userData.boule2noel < _vm.selected.price)?_c('div',{staticClass:"unavalable"},[_vm._v(" Boules insuffisantes ")]):_vm._e(),_c('div',{staticClass:"quantity"},[_vm._v(_vm._s(_vm.selected.quantity - _vm.selected.claimed)+" retants")]),_c('div',{staticClass:"price",class:{
            available: _vm.userData.boule2noel >= _vm.selected.price
          }},[_vm._v(_vm._s(new Intl.NumberFormat().format(_vm.selected.price))+" boules")])]),_c('div',{staticClass:"recap"},[_c('div',{staticClass:"title"},[_vm._v("Récapitulatif")]),_c('div',{staticClass:"prices"},[_c('div',{staticClass:"count current"},[_vm._v(_vm._s(new Intl.NumberFormat().format(_vm.userData.boule2noel))+" "),_c('span',{staticClass:"currency"},[_vm._v(" boules ")])]),_c('div',{staticClass:"count price"},[_vm._v("-"+_vm._s(new Intl.NumberFormat().format(_vm.selected.price))+" "),_c('span',{staticClass:"currency"},[_vm._v(" boules ")])]),_c('div',{staticClass:"count total",class:{
              unavailable: _vm.userData.boule2noel < _vm.selected.price
            }},[_vm._v(_vm._s(new Intl.NumberFormat().format(_vm.userData.boule2noel - _vm.selected.price))+" "),_c('span',{staticClass:"currency"},[_vm._v(" boules ")])])]),_c('div',{staticClass:"confirme",class:{
              unavailable: _vm.userData.boule2noel < _vm.selected.price || _vm.loader
            },on:{"click":function($event){return _vm.buy(_vm.selected)}}},[(_vm.loader)?_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')]):_c('span',[_vm._v("Acheter")])])])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }