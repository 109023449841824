<template>
  <div class="home" :class="{
    selection: selected != null,
    close: !settings.shopOpen
  }">


    <div class="waiting-container" v-if="!settings.shopOpen">

      <div class="text-container">
        La Boutique ouvre Bientot !
      </div>

      <!-- <div class="time">
        {{ countdown.toString()
          .replace(' hours,', 'h').replace(' minutes and', 'm').replace(' seconds', 's')
          .replace(' hour,', 'h').replace(' minute and', 'm').replace(' second', 's')
          .replace(' hour and,', 'h').replace(' hours and', 'm')
        }}
      </div> -->

    </div>

    <div class="page-container">

      <span class="title-header">
        jeux disponible
        <span class="title-count">({{ totalAvailable }})</span>
      </span>

      <div class="game-list">

        <div class="wrapper" v-for="game in orderedGame" :key="game.name" @click="select(game)">
          <div class="game" :style="{
              ...game.style,
              'background-image': `url(${game.imgUrl})`
            }">

            <div class="unavalable" v-if="!game.available">
              Indisponible
            </div>

            <div class="unavalable" v-else-if="game.claimed >= game.quantity">
              Out Of Stock
            </div>

            <div class="unavalable" v-else-if="userData.boule2noel < game.price">
              Boules insuffisantes
            </div>

            <div v-if="game.special" class="special">
              special
              <i class="fa fa-star"></i>
            </div>

            <div class="quantity">{{ game.quantity - game.claimed }} restants</div>
            <div class="price" :class="{
              available: userData.boule2noel >= game.price && game.claimed < game.quantity
            }">{{ new Intl.NumberFormat().format(game.price) }} boules</div>
          </div>
        </div>

      </div>

      <div class="shop-panel" v-if="selected">

        <div class="shop-panel-content">

        <div class="header">
          <span class="name">{{ selected.name.toLowerCase() }}</span>
          <div class="icon-container" @click="selected = null">
            <i class="fas fa-times"></i>
          </div>
        </div>

        <div class="info-container">

          <div class="game" :style="{
              'background-image': `url(${selected.imgUrl})`
            }" >

            <div class="unavalable" v-if="userData.boule2noel < selected.price">
              Boules insuffisantes
            </div>

            <div class="quantity">{{ selected.quantity - selected.claimed }} retants</div>
            <div class="price" :class="{
              available: userData.boule2noel >= selected.price
            }">{{ new Intl.NumberFormat().format(selected.price) }} boules</div>
          </div>

          <div class="recap">
            <div class="title">Récapitulatif</div>

            <div class="prices">
              <div class="count current">{{ new Intl.NumberFormat().format(userData.boule2noel) }} <span class="currency"> boules  </span> </div>
              <div class="count price">-{{ new Intl.NumberFormat().format(selected.price) }} <span class="currency"> boules </span>  </div>
              <div class="count total" :class="{
                unavailable: userData.boule2noel < selected.price
              }">{{ new Intl.NumberFormat().format(userData.boule2noel - selected.price) }} <span class="currency"> boules </span> </div>
            </div>

            <div class="confirme" @click="buy(selected)" :class="{
                unavailable: userData.boule2noel < selected.price || loader
              }">
              <div v-if="loader" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              <span v-else>Acheter</span>
              </div>

          </div>

        </div>


      </div>
      </div>

    </div>

  </div>
</template>

<script>

import uuid from 'uuid/v4'

import axios from 'axios'
import { db } from '../firebase'
import { mapState } from 'vuex'

import moment from 'moment-timezone'
import 'moment-countdown'

import games from './game'

const collectionRef = db.collection('games');

const  runDelete = async _ => {

  const snapshot = await collectionRef.get()

  const docs = []

  snapshot.docs.forEach( async doc => {
    await doc.ref.delete()
  })

  games.forEach((x, i) => {
    db.collection('games').add({
      publicId: uuid(),
      ...x
    })
  })
}

// runDelete()


export default {
  name: 'Home',

   metaInfo () {
    return {
      title: `Boutique - Alxios`
    }
  },

  firestore() {
    return {
      users: db.collection('users').where('userId', '==', this.user.id),
      games: db.collection('games'),
      settings: db.collection('settings').doc('global')
    }
  },

  data () {
    return {
      moment,
      games: [],
      users: [],
      settings: {},
      selected: null,
      loader: false,
    }
  },

  watch: {
    games () {
      setTimeout(_ => {
        this.init3dEffect()
      }, 1000)
    }
  },

  methods: {
    init3dEffect () {
      if (this.init3D) return
      if (window.innerWidth < 500) return

      this.init3D = true

      // eslint-disable-next-line no-undef
      pivot.init({ 
        selector: '.game',
        shadow: true,
        perspective: 1000,
        scale: true
      });
    },

    async buy (game) {
      if (this.loader) return
      if (game.price > this.userData.boule2noel) return

      this.loader = true
      const access_token = localStorage.getItem('access_token')

      await axios.post('https://us-central1-alxios-christmas.cloudfunctions.net/addGame', {
        game_id: game.id,
        access_token,
      })

      this.$router.push({ name: 'request' })

      this.loader = false
    },

    select(game) {
      if (!game.available) return
      if (game.claimed >= game.quantity) return

      this.selected = game
    }
  },

  async mounted () {
    // setInterval(_ => {
    //   // eslint-disable-next-line no-undef
    //   this.countdown = moment().countdown("2021-12-6 18:00:00", countdown.HOURS | countdown.MINUTES  | countdown.SECONDS, NaN)
    // }, 1000)
  },

  computed: {

    totalFinal () {
      return this.games.reduce( (acc, game) => acc + game.quantity, 0)
    },

    totalAvailable () {
      return this.games.reduce( (acc, game) => acc + (game.quantity - game.claimed), 0)
    },

    orderedGame() {
      return this.games
      const test = [...this.games]
      
      .sort((a, b) => {
        return b.price - a.price
      })

      .sort((a, b) => {
        if (this.userData.boule2noel < a.price) return 1
        return -1
      })

      return test
    },

    ...mapState(['user']),
    userData() {
      return this.users.find(x => x.userId === this.user.id) || {}
    }
  },
}

</script>

<style lang="scss" scoped>

.waiting-container {
  position: absolute;
  flex-direction: column;
  top: 0;
  margin-left: -24px;

  left: 0;
  right: 0;
  
  height: 100vh;
  background: rgba( 0, 0, 0, 0.5 );

  backdrop-filter: blur(10px);

  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  .text-container {

    font-size: 24px;

    font-family: 'Poppins';

    color: white;

    font-weight: 300;

    text-shadow: 1px 1px 6px #00000059;
    
  }

  .time {
    margin-top: 21px;
    font-size: 48px;

    font-family: 'Poppins';

    color: white;

    text-shadow: 1px 1px 6px #00000059;

    font-weight: 700;
  }
}

.lds-ellipsis {
  position: relative;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  margin-left: 55px;
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


@media screen and (max-width: 780px) {

  .waiting-container {
    justify-content: start;
    padding-top: 60%;
  }
  .home {
    max-height: calc(100vh) !important;
    padding-bottom: 84px;
    &.selection {
      max-height: calc(100vh - 64px - 330px) !important;
    }
  }

  .info-container {
    justify-content: flex-end !important;
  }

  .prices {
    margin-bottom: 24px !important;
  }

  .shop-panel .game {
    display: none;
  }
}

.shop-panel {
  z-index: 1000000;
  position: fixed;
  bottom: 0;
  background: #202225;
  right: 0;
  left: 0;
  box-shadow: 0px -1px 2px 0 rgba(60, 64, 67, 0.3), 1px -2px 3px 1px rgba(60, 64, 67, 0.15);

  .shop-panel-content {
    max-width: 1500px;
    width: 100%;

    margin: 0 auto;
  }

  .info-container {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
  }

  .confirme {
    background: #37A75E;

    text-align: center;

    padding: 0 .4rem;
    color: white;
    font-weight: 500;
    font-size: 24px;
    line-height: 42px;
    border-radius: 3px;

    align-self: flex-end;
    width: 200px;

    cursor: pointer;

    margin-bottom: 12px;

    transition: .3s ease all;

    &.unavailable {
      background: #E91916;
      opacity: 0.3;

      cursor: not-allowed;

      &:hover {
        background: #E91916;
      }
    }

    &:hover {
      background: lighten($color: #37A75E, $amount: 10);
    }
  }

  .recap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-right: 52px;
    text-align: right;

    font-size: 24px;

    margin: 18px 50px;

    .prices {
      margin-bottom: 100px;
    }

    .title {
      color: white;
      text-transform: uppercase;
    }

    .count {
      font-size: 32px;
      margin: 9px 0;

      font-weight: 400;
      font-family: Open Sans;

      .currency {
        opacity: 0.8;
        font-size: 24px;
        color: white;
      }

      svg {
        margin-left: 8px;
      }
    }

    .total {
      color: #37A75E;
      &.unavailable {
        font-weight: 700;
        color: #E91916;
      }
    }

    .price {
      font-weight: 700;
      color: #E91916;
    }
  }

  .icon-container {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;

    font-size: 24px;
    color: white;

    margin: 0 42px;
    margin-top: 18px;

    .name {
      text-transform: capitalize;
    }
  }
}

.home {
  max-height: calc(100vh);
  overflow: auto;

  position: relative;

  &.close {
    overflow: hidden;
  }

  &.selection {
    max-height: calc(100vh - 476px);
  }
}

.title-header {
  text-transform: uppercase;
  letter-spacing: 1.2px;

  color: white;

  display: flex;
  align-items: center;

  .title-count {
    color: #B9BBBE;

    margin-left: 4px;
    font-size: 12px;

    margin-bottom: -2px;
  }
}

.page-container {
  max-width: 1500px;
  margin: 0 auto;

  padding-top: 32px;
}

@media screen and (max-width: 1850px) {
  .page-container {
      max-width: 1225px;
  }
}

@media screen and (max-width: 1580px) {
  .page-container {
      max-width: 900px;
  }
}

@media screen and (max-width: 1250px) {
  .page-container {
      max-width: 610px;
  }
}


@media screen and (max-width: 900px) {
  .page-container {
      max-width: 256px;
  }
}

.game-list {
  display: flex;
  flex-direction: row;
  
  flex-wrap: wrap;

  margin: 24px 0;

  padding-bottom: 36px;
}

.wrapper {

  &:nth-child(1n) > .game {
    margin-left: 0;
  }

}

.game {
  position: relative;

  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  margin: 18px 42px;
  background: #202225;
  width: 256px;
  height: 380px;
  border-radius: 5px;
  background-position: center center;
  background-size: cover;
  background-image: url(https://image.api.playstation.com/cdn/EP2120/CUSA11235_00/c439UdvQsGkHpQ04PGyPh7rwGfXwLtne.png);

  cursor: pointer;

  .unavalable {
    display: flex;
    position: absolute;
    background: #30333894;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffe2e1;
    text-shadow: -1px 2px 7px #3c2929;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.5px;
}

.special {
    position: absolute;
    top: 0;
    right: 0;

    background: #9147ff;
    padding: 0 .4rem;
    margin: 10px;

    color: white;

    font-size: 14px;
    line-height: 24px;

    border-radius: 3px;

    svg {
      margin-left: 3x;
      color: yellow;
    }
}

  .quantity {
    position: absolute;
    top: 12px;
    left: 12px;

    display: inline;
  }

  .quantity {
    position: absolute;
    top: 0;
    left: 0;

    background: #00000050;
    padding: 0 .4rem;
    margin: 10px;

    color: white;

    font-size: 14px;
    line-height: 24px;

    border-radius: 3px;
  }

  .price {
    position: absolute;
    bottom: 0;
    right: 0;

    background: #E91916;
    padding: 0 .4rem;
    margin: 10px;

    color: white;

    font-weight: 500;

    font-size: 15px;
    line-height: 24px;

    border-radius: 3px;

    &.available {
      background: #37a75e;
    }
  }
}

</style>

